import { ToWords } from 'to-words';
import { useParams, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { sumBy } from 'lodash/fp';
import React, { useEffect } from 'react';

import useTenant from 'components/use-tenant';
import { DetailPage } from 'common/ui/AdminCRUD/page';
import { formatCurrency } from 'utils/formatAmount';
import { formatDate } from 'utils/dateUtils';
import { useFetchApiGet } from 'common/reduxutils';
import DataTable from 'common/ui/DataTable';
import LoadingSpinner from 'common/ui/LoadingSpinner';
import Printable from 'components/Printable';
import PageTitle from 'components/PageTitle';
import businessApiCall from 'apiCalls/business';
import branchApiCall from 'apiCalls/branch';
import orderApiCall from 'apiCalls/order';

const toWords = new ToWords({
  localeCode: 'en-US',
  converterOptions: {
    currency: true,
    ignoreDecimal: false,
    ignoreZeroCurrency: false,
    doNotAddOnly: false,
    currencyOptions: {
      // can be used to override defaults for the selected locale
      name: '',
      plural: '',
      symbol: 'MYR',
      fractionalUnit: {
        name: 'cent',
        plural: 'cents',
        symbol: '',
      },
    },
  },
});

const HeaderComponent = ({
  currentPrintPage,
  totalPrintPage,
  companyInfo,
  branch,
  statementDate,
}) => {
  return (
    <div>
      <div>
        <p>
          <strong>
            {companyInfo.company_name}
            {companyInfo.registration_number && (
              <> ({companyInfo.registration_number})</>
            )}
          </strong>
        </p>
        <p>{companyInfo.address1}</p>
        <p>{companyInfo.address2}</p>
        <p>{companyInfo.address3}</p>
        {companyInfo.phone && <p>Tel: {companyInfo.phone}</p>}
      </div>

      <div className="flex justify-center items-center">
        <p>
          <strong>Sales Statement</strong>
        </p>
      </div>

      <div className="grid grid-cols-12">
        <div className="col-span-6">
          <div className="grid grid-cols-4 gap-2">
            <div>
              <p>Branch:</p>
              <p>Address:</p>
              <p>Salesman:</p>
            </div>
            <div className="col-span-3">
              <p>{branch ? branch.name : '_'}</p>
              <p>{'_'}</p>
              <p>{'_'}</p>
            </div>
          </div>
        </div>
        <div />
        <div className="col-span-5">
          <div className="grid grid-cols-2 gap-2">
            <div>
              <p>Statement Date:</p>
              <p>Page:</p>
            </div>
            <div>
              <p>{statementDate}</p>
              <p>
                {currentPrintPage} of {totalPrintPage}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const DataTableComponent = ({ items, paging }) => {
  return (
    <DataTable
      rowKey="id"
      columns={[
        {
          title: 'Date',
          dataIndex: 'date',
          render: value => formatDate(value),
        },
        {
          title: 'Invoice No.',
          dataIndex: 'code',
        },
        {
          title: 'Customer',
          dataIndex: 'user',
          render: user => user?.name,
        },
        {
          title: 'Branch',
          dataIndex: 'branch',
          render: branch => branch?.name,
        },
        {
          title: 'Amount',
          dataIndex: 'total_amount',
          align: 'right',
          render: value => (value > 0 ? formatCurrency(value) : ''),
        },
      ]}
      dataSource={items}
      totalItems={paging?.total_items}
      currentPage={paging?.current_page || paging?.page}
      defaultCurrent={1}
      defaultPageSize={paging?.page_size || paging?.per_page}
      pageSize={paging?.page_size || paging?.per_page}
      size="small"
    />
  );
};

const SummaryComponent = ({ total }) => {
  return (
    <div className="flex flex-col flex-1">
      <div>
        <hr />
        <div className="flex items-start">
          <div className="mr-2">
            <p>RINGGIT MALAYSIA:</p>
          </div>
          <div className="flex-1">
            <p>{`${toWords.convert(total)}`.toUpperCase()}</p>
          </div>
          <div />
          <div className="ml-2 mr-4">
            <p className="text-right">{formatCurrency(total)}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const StatementItemDetail = ({ business_id, item: branch }) => {
  const {
    data: companyInfo,
    load: fetchCompanyInfo,
    isLoading: isLoadingCompanyInfo,
  } = useFetchApiGet(businessApiCall.detail, { resourceName: 'item' });

  useEffect(() => {
    fetchCompanyInfo({ id: business_id });
  }, [business_id]);

  const urlParams = useParams();
  const params = { status: 1002 };
  const [baseSearchParams] = useSearchParams();
  const searchParams = Object.fromEntries(baseSearchParams);
  const payload = { ...urlParams, ...searchParams, ...params };

  const { data = {}, isLoading: isLoadingData } = useQuery({
    queryKey: [orderApiCall.list.queryKey, payload],
    queryFn: () => orderApiCall.list.queryFn(payload),
  });
  const { items: baseItems = [], paging } = data;
  const isLoading = isLoadingCompanyInfo || isLoadingData;

  const total = sumBy(item => Number(item.total_amount))(baseItems);
  const items = baseItems.toReversed();

  const statementDate =
    searchParams['date__gte'] || searchParams['date__lte']
      ? [
          formatDate(searchParams['date__gte']) || 'Beginning',
          formatDate(searchParams['date__lte']) || 'Present',
        ].join(' - ')
      : formatDate(new Date());

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Printable
      title="Statement"
      HeaderComponent={({ currentPrintPage, totalPrintPage }) => (
        <HeaderComponent
          currentPrintPage={currentPrintPage}
          totalPrintPage={totalPrintPage}
          companyInfo={companyInfo}
          branch={branch}
          statementDate={statementDate}
        />
      )}
      DataTableComponent={({ items }) => (
        <DataTableComponent items={items} paging={paging} />
      )}
      SummaryComponent={() => <SummaryComponent total={total} />}
      items={items}
    />
  );
};

export const StatementPage = props => {
  const { business_id } = useTenant();
  const [baseSearchParams] = useSearchParams();
  const searchParams = Object.fromEntries(baseSearchParams);

  if (searchParams.branch_id) {
    return (
      <DetailPage
        title=""
        apiCall={branchApiCall}
        ItemDetail={StatementItemDetail}
        resourceName="items"
        options={{ id: searchParams.branch_id }}
        {...props}
      />
    );
  }

  return (
    <>
      <PageTitle title="View details" />
      <StatementItemDetail business_id={business_id} />
    </>
  );
};
